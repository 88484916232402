// LocalStorage variables
export const CART_ENTRIES = 'cartEntries';
export const WISHLIST_ENTRIES = 'wishlistEntries';
export const RECENTLY_VIEWED_PRODUCTS = 'rvp';
export const RECENTLY_VIEWED_PRODUCTS_TIMESTAMP = 'rvpt';
export const RVP_LIST_LENGTH_LIMIT = 12;
export const COOKIE_NOTICE = 'cn';
export const CART_MUTATED = '_cartMutated';
export const HYBRIS_CART_STORAGE_VALUE = 'hy';
export const CART_STORAGE_VALUE = 'fs';
export const PDP_VIEWS = 'pdpViews';
export const SIZE_TRANSFER_SIZE = 'sizeTransfersize';
export const SIZE_TRANSFER_LENGTH = 'sizeTransferlength';
export const SIZE_TRANSFER_RANGE_IS_WAIST_LENGTH = 'sizeRangeIsWaistLength';
export const LOGGED_IN_STATUS = 'loggedInStatus';
export const PAGE_VIEWS = 'pageviews';
export const NEWSLETTER_OVERLAY_PAGE_VIEWS = 'newsletterOverlayPageviews';
export const NEWSLETTER_OVERLAY_SHOWN = 'newsletterOverlayShown';
export const NEWSLETTER_OVERLAY_START_TIME = 'newsletterOverlayStartTime';
export const EXPONEA_NOTIFICATION_REJECTED = 'g-star_pushNotifications_disabled';
export const EXPONEA_NOTIFICATION_INPROGRESS = 'endShowingRequestPushNotifications';
export const FIT_ANALYTICS_RECOMMENDATIONS = 'fitAnalyticsRecommendations';
